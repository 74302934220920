import React from "react"
import { graphql } from "gatsby"

// Import Local Components
import Projects from "./components/_projects"

export default ({
  data: {
    allSanityProject: { nodes: projects },
  },
}) => <Projects projects={projects} />

export const query = graphql`
  query MyQuery {
    allSanityProject(sort: { order: DESC, fields: date }) {
      nodes {
        _id
        description
        client
        name
        credits {
          _key
          credited
          type
        }
        images {
          ... on SanityImageSet {
            _key
            _type
            images {
              _key
              altText
              name
              asset {
                id
                _key
                fluid(maxWidth: 800) {
                  ...GatsbySanityImageFluid
                }
                extension
                url
              }
            }
          }
          ... on SanityImageWithAltText {
            _key
            _type
            altText
            name
            isFullWidth
            asset {
              id
              fluid(maxWidth: 800) {
                ...GatsbySanityImageFluid
              }
              extension
              url
            }
          }
        }
        tags {
          name
          id
        }
        highlight {
          hex
        }
      }
    }
  }
`
