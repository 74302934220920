import React, { useState, useEffect, useContext } from "react"
// import { Link } from "gatsby"
import styled from "styled-components"
import intersection from "lodash.intersection"
// import slug from "slug"

import FilterContext from "~context/filter"

import Project from "~components/project"

const FilteredProject = styled(Project)`
  display: ${({ filtered }) => (filtered ? "none" : "block")};
`

export default ({ project }) => {
  if (!project) return null
  const { id, name, images, tags, highlight } = project

  const [filters] = useContext(FilterContext)
  const [filtered, setFiltered] = useState(false)

  useEffect(() => {
    if (filters.length === 0) {
      setFiltered(false)
    } else {
      const filtered = intersection(
        filters,
        tags.map(tag => tag.name)
      )
      if (filtered.length > 0) {
        setFiltered(false)
      } else {
        setFiltered(true)
      }
    }
  }, [filters])

  return (
    <FilteredProject key={id} highlight={highlight.hex} filtered={filtered}>
      <FilteredProject.IntersectionObserver
        projectTags={tags}
        project={project}
      />
      <FilteredProject.Hero
        image={images[0]}
        project={project}
        projectName={name}
      />
      <FilteredProject.Images images={images.slice(1, 3)} project={project} />
      <FilteredProject.IntersectionObserver
        projectTags={tags}
        project={project}
      />
    </FilteredProject>
  )
}
